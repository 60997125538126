<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="orderList"
    ></select-shop>
    <CCard>
      <CCardBody>
        <div class="row">
        <div class="col-md-10 col-sm-9 col-9 font-weight-normal">
        <h2>
            {{ $t("OrderListTXT") }}
            <!-- <p style="font-style: italic; font-size: 12px; display: inline">
              ({{ $t("lastUpdated") }} )
            </p> -->
          </h2>
        </div>
        <div class="col-md-2 col-sm-3 col-3 font-weight-normal">
        <CButton  block color="outline-success" @click="orderList()"
            > <i class="fa-solid fa-rotate"></i> {{ $t('sync') }}</CButton>
        </div>
        </div>
        <div class="row">
        <div class="col-md-10">
        <show-date></show-date>
        </div>
        <div class="col-md-2 col-sm-3 col-3 font-weight-normal">
        <CButton  block color="outline-success" @click="AddCheckStockModal = true"
            > <i class="fa fa-print" style="color: #3abc98; margin-right: 8px"></i> {{ $t('พิมพ์ใบปะหน้าทั้งหมด') }}</CButton>
        </div>
        </div>
        <hr/>
        <!-- <CRow>
        <CCol sm="12" lg="12">
          <div class="sorting-container">
              <span class="sorting-label">{{ $t('sortBy') }} : </span>
              <div class="sorting-buttons">
                <CButton :class="getButtonClass('optionItem.name')" v-on:click="toggleSort('optionItem.name')">
                  {{ $t('status') }} <i :class="getArrowClass('optionItem.name')"></i>
                </CButton>
                <CButton :class="getButtonClass('quantity')" v-on:click="toggleSort('quantity')">
                  {{ $t('orderTime') }} <i :class="getArrowClass('quantity')"></i>
                </CButton>
                <CButton :class="getButtonClass('total')" v-on:click="toggleSort('total')">
                  {{ $t('completeTime') }} <i :class="getArrowClass('total')"></i>
                </CButton>
              </div>
            </div>
        </CCol>
        </CRow> -->
        <div style="overflow-x: auto">
          <DataTable
            :items="items"
            :fields="fields"
            @row-clicked="toOrderDetail"
            hover
            border
            responsive
            clickableRows
            style="white-space: nowrap"
            :row-class="(item) => item._classes"
          >
          </DataTable>    
        </div>
        <pagination 
          :meta_data="meta_data" 
          v-on:next="handleNextPage"
        ></pagination>
        <CModal :show.sync="showSyncLoading" centered >
          <div>
            <div class="d-flex flex-column align-items-center">
              <CSpinner color="success" size="xl" style="width: 80px; height: 80px; aspect-ratio: 1/1;" />
              <p class="mt-3">Syncing...</p>
            </div>
          </div>
          <template #footer>
          <div class="text-center w-100"><CButton color="danger" class="w-50" @click="closeSuccessModal">{{ $t('cancel') }}</CButton>
          </div>
            
          </template>
        </CModal>
        <CModal :show.sync="errorModel" centered color="danger" size="lg" >
          <div style="padding:5%;">
              <h3 class="text-center">{{ textError }}</h3>
          </div>
          <template #footer>
          <div class="text-center w-100"><CButton color="danger" class="w-50" @click="errorModel = false">{{ $t('submit') }}</CButton>
          </div>
          </template>
        </CModal>
        <!-- Sync Success Modal -->
        <CModal :show.sync="showSyncSuccess" centered color="success" :title="$t('syncSuccess')">
          <div class="text-center">
            <CIcon name="cil-check-circle" size="4xl" class="text-success" />
            <p class="mt-4">{{ $t('pleaseCheckProductSync') }}</p>
          </div>
          <template #footer>
          <div class="text-center w-100"><CButton color="success" class="w-50" @click="closeSuccessModal">{{ $t('submit') }}</CButton>
          </div>  
        </template>
        </CModal>
        <CModal :show.sync="errorModal" color="danger" :title="$t('pullproductFail')" centered size="lg"
        >
          <div>
            <br />
            <h4 class="text-center">{{ $t('plsLoginShopee') }}</h4>
            <br />
          </div>
          <template #footer>
            <CRow class="col-12 justify-content-center">
              <CCol class="text-center" col="6">
                <CButton color="light"  block @click="authorize()">
                  {{ $t('submit') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
        <CRow>
      <CModal :show.sync="AddCheckStockModal" centered :footer="footer" size="lg" style="max-width: 915px;">
        <template #header-wrapper class="header-modal">
          <header class="modal-header header-modal">
            <h4 class="modal-title font-weight-normal">
              {{ $t('เลือกพัสดุที่ต้องการพิมพ์ใบปะหน้า') }}
            </h4>
            <button type="button" aria-label="Close" class="close" @click="AddCheckStockModal = false">
              ×
            </button>
          </header>
        </template>
        <template #body-wrapper>
          <CCardBody>
            <div style="max-height: 400px; overflow-y: auto;">
              <CDataTable :fields="orderFields" :items="items" hover border v-model="checkStockItem"
                clickableRows >
                <template slot="nameproduct-header">
                  <p></p>
                </template>
                <template #selected="{ item, index }">
                  <td style="vertical-align: middle; text-align: center;">
                    <div class="custom-control custom-checkbox " style="padding-left: 2rem">
                      <input type="checkbox" class="custom-control-input success cursor" v-model="item.selected"
                        @change="onSelectOrder(item)" :id="index" />
                      <label class="custom-control-label cursor" :for="index">
                      </label>
                    </div>
                  </td>
                </template>
                <template #name="{ item }">
                  <td class="text-dark" style="vertical-align: middle;border-left: hidden;">
                    {{ item.note }}
                  </td>
                </template>
                <template #no-items-view>
                  <div class="text-center my-5">
                    <h4 style="color: #ced2d8;">
                      {{ $t('noItem') }}
                    </h4>
                  </div>
                </template>
              </CDataTable>
            </div>
          </CCardBody>
        </template>
        <template #footer-wrapper>
          <CRow class="justify-content-end col-md-12">
            <CCol col="12" lg="6" class="row justify-content-end">
              <div class="col-6 align-items-end">
                <CButton block color="success" v-if="loadingButton === true" v-on:click="onCheckboxChange">
                  {{ $t('printShippingLabel') }}
                </CButton>
                <CButton block color="success" v-else-if="loadingButton === false" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('printShippingLabel') }}
                </CButton>
              </div>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from "@/containers/DataTable";
import { CButton, CCardBody, CModal, CModalBody, CSpinner, CIcon, CBadge } from "@coreui/vue"
import Pagination from '@/containers/Pagination'
import ecommerce from "@/services/ecommerce"
import moment from 'moment'
import util from '@/util/util'

export default {
  components: { DataTable, CButton, CCardBody, CModal, CModalBody, CSpinner, CIcon, CBadge, Pagination },

  data() {
    return {
      AddCheckStockModal: false,
      errorModal:false,
      orderSN: {},
      loadingButton: true,
      showSyncLoading: false,
      showSyncSuccess: false,
      errorModel: false,
      orders: [],
      textError:'',
      meta_data: {
          last_page: null,
          current_page: 1,
          prev_page_url: null,
          items: 0,
          limit: null,
          itemCount: null,
      },
      showNextButton: false,
      exists: []
    };
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'access']),
    uid() {
      return `${localStorage.getItem('shopsUid')}`
    },
    isPermission() {
      return permis.findPermissionRead('inventory', this.$route.path)
    },
    isEditData() {
      return permis.findPermissionEdit('inventory', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('inventory', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD-MM-YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD-MM-YYYY')
    },
    fields() {
      return [
        {
          key: "order_sn",
          label: this.$i18n.t("orderNumber"),
          _classes: "text-dark font-weight-normal",
        },
        {
          key: "order_status",
          label: this.$i18n.t("status"),
          _classes: "text-dark text-left font-weight-normal",
        },
        {
          key: "buyer_username",
          label: this.$i18n.t("customerAccount"),
          _classes: "text-dark text-left font-weight-normal",
        },
        {
          key: "order_time",
          label: this.$i18n.t("orderTime"),
          _classes: "text-dark text-left font-weight-normal",
        },
        {
          key: "pay_time",
          label: this.$i18n.t("paymentTime"),
          _classes: "text-dark text-left font-weight-normal",
        },
        {
          key: "shipping_carrier",
          label: this.$i18n.t("shipping"),
          _classes: "text-dark text-left font-weight-normal",
        },
        {
          key: "total_amount",
          label: this.$i18n.t("totalAmount"),
          _classes: "text-dark text-right font-weight-normal",
        },
        {
          key: "currency",
          label: this.$i18n.t("currency"),
          _classes: "text-dark text-left font-weight-normal",
        },
        {
          key: "status",
          label: this.$i18n.t("status"),
          _classes: "text-dark text-left font-weight-normal",
        },
      ];
    },
    orderFields() {
      return [
        {
          key: 'selected',
          label: this.$i18n.t('#'),
          _style: 'width:5%; vertical-align: middle;',
          _classes: 'text-left text-dark font-weight-normal',
        },
        {
          key: "order_sn",
          label: this.$i18n.t("orderNumber"),
          _classes: "text-dark font-weight-normal",
        },
        {
          key: "order_status",
          label: this.$i18n.t("status"),
          _classes: "text-dark text-left font-weight-normal",
        },
        {
          key: "buyer_username",
          label: this.$i18n.t("customerAccount"),
          _classes: "text-dark text-left font-weight-normal",
        },
        {
          key: "shipping_carrier",
          label: this.$i18n.t("shipping"),
          _classes: "text-dark text-left font-weight-normal",
        },
        {
          key: "total_amount",
          label: this.$i18n.t("totalAmount"),
          _classes: "text-dark text-right font-weight-normal",
        },
        {
          key: "currency",
          label: this.$i18n.t("currency"),
          _classes: "text-dark text-left font-weight-normal",
        },
        {
          key: "status",
          label: this.$i18n.t("status"),
          _classes: "text-dark text-left font-weight-normal",
        },
      ];
    },
    items() {
      let data = this.orders
      let detail = []
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        let currency = ''
              if(item.currency == 'THB'){
                currency = this.$t('baht')
              }
        let status = ''
        if (this.exists.includes(item.order_sn)) {
          status = this.$t('existsBill');
        } else {
          status = this.$t('noExistsBill');
        }
        let orderStatus = ''
        if (item.order_status == 'UNPAID'){
          orderStatus = this.$t('payWaiting') 
        } else if (item.order_status == 'READY_TO_SHIP'){
          orderStatus = this.$t('readyToShip')
        } else if (item.order_status == 'PROCESSED'){
          orderStatus = this.$t('shipping')
        } else if (item.order_status == 'SHIPPED'){
          orderStatus = this.$t('shipped')
        } else if (item.order_status == 'COMPLETED'){
          orderStatus = this.$t('complete')
        } else if (item.order_status == 'IN_CANCEL'){
          orderStatus = this.$t('incancel')
        } else if (item.order_status == 'INVOICE_PENDING'){
          orderStatus = this.$t('invoicePending')
        } else if (item.order_status == 'CANCELLED'){
          orderStatus = this.$t('cancelled')
        } else {
          orderStatus = '-'
        }
        let classes = 'text-dark'
        if(item.order_status == 'CANCELLED'){
          classes = 'text-danger'
        }
        detail.push({
                order_sn: item.order_sn,
                buyer_username: item.buyer_username,
                order_status: orderStatus,
                order_time:item.create_time ? moment.unix(item.create_time).format('DD/MM/YYYY HH:mm') : '-',
                pay_time: item.pay_time ? moment.unix(item.pay_time).format('DD/MM/YYYY HH:mm') : '-',
                shipping_carrier: item.shipping_carrier,
                total_amount: util.convertCurrency(item.total_amount),
                currency: currency,
                status: status,
                _classes: classes,

        })
      }
      return detail
    },
  },
  created() {
    this.orderList()
    this.shop = this.shops.find((i) => this.shopObjectId === i.objectId)
  },
  mounted() {
  if (this.shop.connectEcommerce === undefined || this.shop.connectEcommerce != true) {
    this.$router.push('/dashboard');
    }
  },
  methods: {
    onCheckboxChange(){

    },
    onSelectCheckStockDoc(item) {
      item.selected = !item.selected;
    },
    authorize() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const params = {
        shopObjectId: shopObjectId,
        uid: uid,
        app: 'order'
      }
      // const headers = {
      //   shopObjectId: shopObjectId,
      // }
      ecommerce({
        url: 'api/v1.0/shopee/signin/link',
        params: params,
        method: 'GET',
        // headers: headers,
      })
        .then((response) => {
          const url = response.data.data.url || '-'
          if (url !== '-') {
            window.open(url, '_blank'); // เปิด URL ในแท็บใหม่
          }
        })
        .catch((error) => {
          console.log(error)
        })
    

      // window.location.href = '/ConnectedSuccess';
    },
    orderCheck() {
      const params = {
        shopObjectId: this.shopObjectId,
        orders: this.orderSN,
      };
      ecommerce({
        url: "/api/v1.0/shopee/order/check",
        params: params,
        method: "GET",
      })
      .then((response) => {
        if (response.data.error.message !== 'success') {
          this.errorModel = true
          this.textError = this.$t('systemError')
        } else if (response.data.data.length === 0) {
          this.errorModel = true
          this.textError = this.$t('systemError')
        } else {
          this.exists = response.data.data.exists
        }
      })
      .catch((error) => {
        console.error(error)
      });
    },
    orderList(page = 1) {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId
      const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
      const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')
      const params = {
        shopObjectId: shopObjectId,
        from: startAt,
        to: endAt,
        page: page
      }
      
      ecommerce({
        url: "/api/v1.0/shopee/order/list",
        params: params,
        method: "GET",
      })
        .then((response) => {
          if(response.data.error.code == 401){
            this.errorModal = true
          } else if (response.data.error.message == 'Receipt Error:Session Expired.') {
            this.errorModal = true
          } else if (response.data.error.message == 'Receipt Error:Start time must be earlier than end time and diff in 15days.'){
            this.errorModel = true
            this.textError = this.$t('morethan15days')
          } else if (response.data.error.message == "Receipt Error:Time range too short."){
            this.errorModel = true
            this.textError = this.$t('pleaseChooseMoreTimeRange')
          }else if(response.data.error.message =="Receipt Error:Forbidden"){
            this.errorModal = true
          }
          else if(response.data.error.message != 'success' ){
            this.errorModel = true
            this.textError = this.$t('systemError')
          } else if (response.data.data == []) {
            this.errorModel = true
            this.textError = this.$t('systemError')
          }   else {
            const data = response.data.data
            if (data.more === true) {
              this.showNextButton = true
              this.meta_data.last_page = page + 1
              this.meta_data.current_page = page
              this.meta_data.itemCount = response.data.data.length
              this.meta_data.limit  = response.data.data.length
            } else {
              this.showNextButton = false
              this.meta_data.last_page = this.meta_data.last_page
              this.meta_data.current_page = this.meta_data.current_page  + 1
              this.meta_data.itemCount = this.meta_data.itemCount
              this.meta_data.limit  = this.meta_data.limit
              this.meta_data.prev_page_url = this.meta_data.current_page - 1
            }
            const orderListSN = data.order_list
            const orderSN = orderListSN.map((item) => item.order_sn).join(",")
            this.orderSN = orderSN
            this.orderDetail()
            this.orderCheck()
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },
    handleNextPage() {
      if (this.showNextButton) {
        const nextPage = this.meta_data.current_page + 1
        this.orderList(nextPage)
      } else {
        const nextPage = this.meta_data.current_page - 1
        this.orderList(nextPage)
      }
    },
    orderDetail() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const params = {
        shopObjectId: shopObjectId,
        orders: this.orderSN,
      };

      ecommerce({
        url: "/api/v1.0/shopee/order/detail",
        params: params,
        method: "GET",
      })
        .then((response) => {
          if (response.data.error.message !== 'success') {
            this.errorModel = true
            this.textError = this.$t('systemError')
          } else if (response.data.data.length === 0) {
            this.errorModel = true
            this.textError = this.$t('systemError')

          } else {
            const data = response.data.data;
            this.orders = data
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toOrderDetail(item) {
      this.$router.push('/ecommerce/OrderDetail/' + item.order_sn)
    },
    syncOrder() {
      this.showSyncLoading = true; // เปิด modal SyncLoading

      // รอ 10 วินาทีแล้วแสดง SyncSuccess modal
      setTimeout(() => {
        this.showSyncLoading = false; // ปิด modal SyncLoading
        this.showSyncSuccess = true;  // เปิด modal SyncSuccess
      }, 10000); // 10 วินาที (10000 มิลลิวินาที)
    },
    closeSuccessModal() {
      this.showSyncSuccess = false; // ปิด modal SyncSuccess
    },
    toggleSort(key) {
      if (this.sortBy === key) {
        this.sortDesc = this.sortDesc === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = key;
        this.sortDesc = 'asc';
      }
    },
  },
};
</script>
<style scoped>
.sorting-container {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #dfe1e5;
}

.sorting-label {
  margin-right: 10px;
  color: #47525e;
  font-weight: 500;
}

.sorting-buttons {
  display: flex;
  gap: 10px; /* Increased gap for more spacing */
}
.btn-inactive {
  background-color: white;
  color: #47525e;
}

.btn-inactive:hover {
  background-color: #e9ecef;
}

.btn-active {
  background-color: #3abc98;
  color: white;
}
</style>